import { UserProvider } from "@auth0/nextjs-auth0/client";
import type { AppProps } from "next/app";
import { ToastContainer } from "react-toastify";
import Head from "next/head";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import "react-toastify/dist/ReactToastify.min.css";
import "tailwindcss/tailwind.css";
import "../styles/globals.css";
import React from "react";
import { useRouter } from "next/router";
import { PostHogIdentifier } from "@app/components/shared/posthog-identifier";

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
  });
}

const contextClass = {
  success: "bg-green-600",
  error: "bg-red-600",
  info: "bg-gray-600",
  warning: "bg-orange-400",
  default: "bg-indigo-600",
  dark: "bg-white-600 font-gray-300",
};

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  React.useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture("$pageview");
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PostHogProvider client={posthog}>
      <UserProvider>
        <Head>
          <title>Anticoagul.ar</title>
        </Head>
        <Component {...pageProps} />
        <ToastContainer
          className="bg-"
          toastClassName={(context) =>
            contextClass[context?.type || "default"] +
            " relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
          }
          bodyClassName={() => "text-sm font-white font-med inline-flex p-3"}
          position="bottom-left"
          autoClose={5000}
          icon={false}
          draggable={false}
          pauseOnHover
        />
        <PostHogIdentifier />
      </UserProvider>
    </PostHogProvider>
  );
}
export default MyApp;
