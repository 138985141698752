import React from "react";
import { usePostHog } from "posthog-js/react";
import { useUser } from "@auth0/nextjs-auth0/client";

export function PostHogIdentifier() {
  const posthog = usePostHog();
  const { user } = useUser();

  React.useEffect(() => {
    if (posthog && user?.email) {
      posthog?.identify(user?.email);
    }
  }, [posthog, user]);

  return null;
}
